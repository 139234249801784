<template>
    <div class="pageContainer targetPage flexCloumn" style="height:unset!important;">

        <div class="flexStartUp" style="width:100%;">
            <el-row class="searchBar flexBetween flex_1" style="flex-wrap: wrap;">

                <el-input class="input_css" type="textarea" rows="10" v-model="bodyArr"></el-input>
                <div class="mt10 flexStart">
                    <div class="flexCenter pointer" @click="goPage"
                        style="font-size:16px; height: 25px;background-color:#fff;color:#5975FF;border:1px solid #5975FF;border-radius:5px;padding:0 12px;white-space:nowrap;">
                        <i class="el-icon-s-home mr10" style="font-size:16px;"></i>
                        返回
                    </div>
                    <span class="ml10" style="background-color:#b3d8ff;">蓝：#b3d8ff; </span>
                    <span style="background-color:#e1f3d8;">绿：#e1f3d8; </span>
                    <span style="background-color:#faecd8;">橙：#faecd8; </span>
                    <span style="background-color:#fde2e2;">红：#fde2e2; </span>
                    <span style="background-color:#e9e9eb;">灰：#e9e9eb; </span>
                </div>
            </el-row>
            <div style="width:200px;">
                <div class="flexStart" style="flex-wrap:nowrap;">
                    <span style="width:100px;">表头背景颜色</span> <el-input v-model="bgcolor" size="mini"
                        style="width:100px;"></el-input>
                </div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">表头文字颜色</span> <el-input
                        v-model="color" size="mini" style="width:100px;"></el-input></div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">表头文字加粗</span> <el-input
                        v-model="thin" size="mini" style="width:100px;"></el-input></div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">主体文字加粗</span> <el-input
                        v-model="thin1" size="mini" style="width:100px;"></el-input></div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">隔行背景颜色</span> <el-input
                        v-model="bgcolor1" size="mini" style="width:100px;"></el-input></div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">列宽</span> <el-input
                        v-model="width" size="mini" style="width:100px;"></el-input></div>
                <div class="flexStart" style="flex-wrap:nowrap;"><span style="width:100px;">合计行</span> <el-input
                        v-model="isTotal" size="mini" style="width:100px;"></el-input></div>
                <div class="flexEnd" style="width:100%;">
                    <el-button class="mt10" type="primary" size="small" @click="make()" style="width:100px;">生成表格
                    </el-button>
                </div>
            </div>
        </div>


        <div class="dividerBar"></div>

        <el-table :data="tableData" row-key="id" size="mini" border :header-cell-style="headerCellStyle"
            :row-style="onTableRowClassName" style="width:fit-content;width:100%;margin-top:20px;">
            <el-table-column v-for="(item, index) in header" :label="item" :prop="item" :width="width" :align="align" />
        </el-table>
    </div>
</template>

<script>


export default {
    data() {
        return {
            bodyArr: `[
	["标题1", "标题2", "标题3", "标题4", "标题5"],
	[1, 2, 3, 4, 5],
	[6, 7, 8, 9, 10]
]`,
            bodyArr: `标题1\t标题2\t标题3\t标题4\t标题5;
1\t2\t3\t4\t5;
6\t7\t8\t9\t10`,
            align: 'center',
            width: '150',
            searchForm: {
                login_name: "",
                firm_name: "",
                firm_num: "",
                tel: "",
                info: "",
                email_status: "",
                is_cooperation: "",
                check_status: "",
                orderBy: {},
            },
            header: [],
            tableData: [],
            color: '#ffffff',
            bgcolor: '#78A3E0',
            bgcolor1: '#e9e9eb',
            thin: 1,
            thin1: 1,
            isTotal: 0,
        };
    },
    filters: {
        handleDate(value) {	// value是输入框的内容，也是要显示的内容
            if (value) {
                return value.substring(0, 10)
            }
        }
    },
    mounted() {

        this.make()
    },

    methods: {
        headerCellStyle({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return `background-color: ${this.bgcolor}; color: ${this.color};font-weight:${this.thin == 1 ? '700' : '500'} `;
            }
        },
        onTableRowClassName({ row, rowIndex }) {
            console.log(88888, rowIndex, this.tableData.length - 1, this.isTotal)
            if ((rowIndex == (this.tableData.length - 1)) && this.isTotal == 1) {
                return {
                    "background-color": ` ${this.bgcolor}`,
                    "color": ` ${this.color}`,
                    "font-weight": `${this.thin == 1 ? '700' : '500'} `
                }
            } else {
                if (rowIndex % 2 != 0) {
                    return {
                        "background": `${this.bgcolor1} !important`,
                        "font-weight": `${this.thin1 == 1 ? '700' : '500'}`
                    }
                } else {
                    return {
                        "font-weight": `${this.thin1 == 1 ? '700' : '500'}`
                    }
                }
            }
        },
        make1() {
            let data1 = JSON.parse(JSON.stringify(this.bodyArr))
            data1 = data1.replace(/[\n\t\s]+/g, '');
            console.log(911111, this.isJSON(data1))
            let data = JSON.parse(data1)

            this.header = data[0]
            data = JSON.parse(JSON.stringify(data))
            // 转换为对象数组
            let objArray = data.slice(1).map(item => {
                let obj = {};
                data[0].forEach((key, index) => {
                    obj[key] = item[index] || '';
                });
                return obj;
            });

            this.tableData = objArray
            console.log(9898, this.tableData);

        },
        make() {
            // let data1 = JSON.parse(JSON.stringify(this.bodyArr))
            // data1 = data1.replace(/[\n\t\s]+/g, '');
            // console.log(911111, this.isJSON(data1))
            // let data = JSON.parse(data1)
            // let data = data1


            let data1 = JSON.parse(JSON.stringify(this.bodyArr))
            // data1 = data1.replace(/[\n\t\s]+/g, '');
            // data1 = data1.replace(/\n/g, '');
            data1 = data1.replace(/(^\s*)|(\s*$)/g, "");

            let data = data1.split(';')
            let arr = []
            for (let item1 of data) {
                console.log(170, item1)
                if (item1.indexOf('\n')==0) {
                    item1 = item1.substring(1, item1.length)
                }
                console.log(173, item1)

                let a = item1.split('\t')
                arr.push(a)
                console.log(171, a)
            }
           
            console.log(92222,  arr,)
            // return
            this.header = arr[0]
            let resultdata = JSON.parse(JSON.stringify(arr))
            // 转换为对象数组
            let objArray = resultdata.slice(1).map(item => {
                let obj = {};
                resultdata[0].forEach((key, index) => {
                    obj[key] = item[index] || '';
                });
                return obj;
            });

            this.tableData = objArray
            console.log(9898, this.tableData);

        },
        goPage() {

            this.$router.push("/jydcsjtj")
        },
    }
};
</script>

<style scoped lang="less">
/deep/ .el-form-item {
    margin-bottom: 0px;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 120px;
            padding-left: 8px;
        }

    }

    .imgaddbtn {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dotted #ccc;
        font-size: 30px;
        cursor: pointer;
    }

    .imgremove {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;

        cursor: pointer;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
    }

    .imgremove:hover {
        color: orange;
    }
}
</style>